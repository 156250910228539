.post-content {
  ul.task-list {
    margin-left: 0;
  }

  li.task-list-item:has(> .task-list-item-checkbox) {
    margin-left: 0;
    list-style-type: none;

    input[type=checkbox] {
      width: 1.8em;
      height: 1.8em;
      line-height: 1.8em;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5em;
    }
  }

  li.task-list-item {
    margin-left: 1.5em;

    > .task-list {
      margin-left: 1.5em;
    }
  }
}
